<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      :isResetFields="false"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/role.edit.js'
import { useStore } from 'vuex'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import _ from 'lodash' // 导入loadsh插件
import { useRoute, useRouter } from 'vue-router'
// import { getGlobalParamsOptionsAsync } from '@/utils/common'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const routes = useRoute()
const router = useRouter()
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

pageTitle.value = t('general.router-role-edit')
const id = routes.params.id

const store = useStore()
const pageInfo = ref({})

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const roleInfo = await store.dispatch('user/getCurrentRoleList', { id })

  if (JSON.stringify(roleInfo) !== '{}') {
    roleInfo.type = roleInfo.type.toString()
    roleInfo.groups = roleInfo.groups && roleInfo.groups.split(',')
  }
  pageInfo.value = roleInfo
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  // const groupList = await getGlobalParamsOptionsAsync('groupList', {
  //   isNeedPreFix: false
  // })
  // const groupsArray = []
  // form.groups.map((item) => {
  //   for (const group in groupList) {
  //     if (groupList[group].value === item) {
  //       groupsArray.push(item)
  //     }
  //   }
  // })

  form.groups = form.groups && form.groups.length ? form.groups.join(',') : ''
  form.id = id
  handleMessageCommit('user/handleEditRoleAction', form).then(() => {
    router.push('/user/role')
  })
}

getCurrentPageInfo()
</script>
